* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  position: relative;
}

/* Chat */
.home-page {
  width: 400px;
  height: 300px;
  background-color: #131324;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 20px;
}

.home-page img {
  width: 50px;
  margin-bottom: 20px;
}

.home-page .btn {
  text-align: center;
  text-decoration: none;
  background-color: #997af0;
  color: #fff;
  text-transform: uppercase;
  width: 200px;
  padding: 1rem 2rem;
  border: none;
  font-weight: bold;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 0.4rem;
  transition: 0.5s ease-in-out;
}

.home-page .btn {
  background-color: #4e0eff;
}

/* Login */
.brand {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}

.brand img {
  height: 8rem;
  /* background-color: red; */
}

.brand h1 {
  color: #ffffff;
  text-transform: uppercase;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem 5rem;
  background-color: #00000076;
  border-radius: 2rem;
  position: relative;
  overflow: hidden;
}

form input {
  background-color: transparent;
  color: #fff;
  padding: 1rem;
  border: 0.1rem solid #4e0eff;
  border-radius: 0.4rem;
  font-size: 1rem;
  width: 100%;
}

form input:focus {
  border: solid 0.1rem #997af0;
  outline: none;
}

form button {
  background-color: #997af0;
  color: #fff;
  text-transform: uppercase;
  width: 100%;
  padding: 1rem 2rem;
  border: none;
  font-weight: bold;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 0.4rem;
  transition: 0.5s ease-in-out;
}

form button:hover {
  background-color: #4e0eff;
}

span {
  color: #fff;
}

span a {
  color: #4e0eff;
  font-weight: bold;
  text-decoration: none;
}

.registration-msg {
  color: #fff;
}

.loader {
  color: #fff;
  width: 50px;
  height: 50px;
  border: #a7a4a4a8 5px dotted;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  animation: rotate 2s infinite ease-in-out;
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.register-loader {
  width: 100%;
  height: 100%;
  background-color: #00000048;
  z-index: 20;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hidden {
  display: none;
}

/* Chat Home */

.chat-container {
  width: 100lvw;
  height: 100lvh;
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.chat-left-container {
  width: 30%;
  height: 100%;
  position: relative;
}

.chat-container .chat-left-container .add-icon {
  color: #fff;
  font-size: 2.5rem;
  position: absolute;
  bottom: 5%;
  right: 5%;
  cursor: pointer;
}

/* Chat Header */

.chat-header {
  padding: 0.7rem 1rem;
  color: #131324;
  width: 100%;
  height: 70px;
  background-color: #d9d9d9;
  border-right: 1px solid #131324;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  cursor: pointer;
  position: relative;
  transition: 0.5s;
}

.chat-header h3 {
  width: 60%;
  margin-left: 1rem;
}

.chat-header .profile-pic {
  height: 70%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  overflow: hidden;
  background-color: #131324;
  height: 50px;
  filter: saturate(-150);
  width: 50px;
  border: 1px solid #131324;
  border-radius: 50%;
}

.chat-header .options {
  display: block;
  position: absolute;
  max-height: 00px;
  max-width: 200px;
  top: 65%;
  right: 5px;
  opacity: 0;
  transform: translateY(5px);
  font-size: 0.8rem;
  background-color: #131324 !important;
  box-shadow: #d9d9d9 0px 0px 5px;
  border-radius: 5px;
  transition: 1s;
  z-index: 10;
  overflow: hidden;
}

.chat-header .options::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #131324;
  display: inline-block;
  position: absolute;
  top: -5px;
  right: 12px;
  transform: rotate(45deg);
}

.chat-header .icon {
  height: 16px;
}

.chat-header .options.grow {
  max-height: 100px;
  opacity: 1;
  transition: 1s;
}

.chat-header .option {
  color: #fff;
  padding: 10px 20px 5px;
  width: 200px;
  user-select: none;
}

/* Add-chat contaner  */

.chat-container .add-chat-container {
  width: 100%;
  height: 100%;
  padding: 2rem;
  border-right: 1px solid #d9d9d9;
  background-color: #0a0a13;
  position: absolute;
  top: 0px;
  overflow: hidden;
  transition: 0.5s ease-out;
  z-index: 105;
}

.chat-container .add-chat-container .icon {
  font-size: 2rem;
  color: #fff;
}

.chat-container .add-chat-container .input-container {
  position: relative;
  margin-bottom: 1rem;
}

.chat-container .add-chat-container .input-container .search-input {
  padding: 0.5rem 1rem;
  width: 100%;
  font-size: 1rem;
  border: 1px solid #001100;
  outline: none;
  border-radius: 10px;
}

.chat-container
  .add-chat-container
  .input-container
  .search-input::placeholder {
  color: #4e0eff;
  font-size: 1rem;
}

.chat-container .add-chat-container .input-container .search-icon {
  font-size: 1.5rem;
  color: #4e0eff;
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translate(0%, -50%);
  cursor: pointer;
}

.chat-container .add-chat-container.move {
  top: -100%;
}

/* ChatBody */

.chat-container .chat-right-container {
  width: 70%;
  height: 100%;
  color: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: absolute;
  top: 0;
  right: 0;
}

.chat-container .chat-body {
  width: 100%;
  height: 100%;
  border-left: 1px solid #fff;
  color: #fff;
  display: flex !important;
  flex-direction: column !important;
}

.chat-container .chat-body .chat-body-header {
  width: 100%;
  height: 70px;

  padding: 0.7rem 1rem;
  background-color: #d9d9d9;
  color: #131324;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

.chat-body-header h1 {
  width: 80%;
  cursor: pointer;
}

.chat-body-header .profile-picture {
  height: 100%;
  border: #131324 solid 1px;
  aspect-ratio: 1/1;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
}

.chat-container .chat-body .chat-body-header .chat-close-icon {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 50%;
  right: 10px;
  color: #4e0eff;
  transform: translateY(-50%);
  display: none;
}

.chat-container .chat-body .chat-main {
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 2px;
  background-color: #000;
}

::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 10px;
  width: 1px;
}

.chat-container .chat-body .input-container {
  height: 100px;
  padding-left: 10px;
  display: flex;
  align-items: end;
  justify-content: space-between;
  position: relative;
  /* background-color: #4e0eff; */
}

.chat-container .chat-body .input-container .input-msg {
  width: 90%;
  max-height: 100px;
  outline: none;
  border: #fff 2px solid;
  background-color: transparent;
  color: #fff;
  /* -webkit-text-stroke: #fff 0.5px; */
  text-shadow: 0px 0px 5px #fff;
  resize: none;
  font-size: 1.2rem;
  padding: 7px 15px;
  border-radius: 5px;
  caret-color: #fff;
}

.input-msg::selection {
  color: #4e0eff;
  background-color: #fff;
}

.chat-container .chat-body .input-container button {
  width: 10%;
  height: 70%;
  background-color: transparent;
  outline: none;
  border: none;
  display: flex;
  align-items: end;
  justify-content: center;
  cursor: pointer;
}
.chat-container .chat-body .input-container button .icon {
  font-size: 2rem;
  color: #fff;
}

.chat-container .chat-body .input-container button .icon:hover {
  color: #4e0eff;
}

/* Contacts */
.contacts-container {
  width: 100%;
  height: 100%;
  border-right: 1px solid #d9d9d9;
  overflow-y: scroll;
  background-color: #131324;
  position: relative;
}

/* Contact */

.contact {
  padding: 0.7rem 1rem;
  width: 100%;
  background-color: #8b8b8f11;
  height: 70px;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  transition: 0.5s;
}

.contact:hover {
  background-color: #0a0a13;
}

.contact.selected {
  background-color: #0a0a13;
}
.contact.selected:hover {
  background-color: #8b8b8f11;
}
.contact.online-user {
  color: red;
}

.contact .profile-picture {
  height: 50px;
  filter: saturate(-150);
  width: 50px;
  background-color: #4e0eff;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
}

.contact .contact-name {
  margin-left: 20px;
  display: flex;
  width: 80%;
  flex-direction: column;
}

.contact .add-contact-icon {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
}

.contact .contact-name .last-message {
  color: #ffffff48;
  font-size: 0.7rem;
  font-variant: normal;
  text-transform: none;
}

.contact .unread {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  padding: 10px;
  border-radius: 50%;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact .unread.active {
  background-color: #4e0eff;
}

/* ChatContainer */

.chat-main {
  height: 80%;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
}

.chat-main .bubble {
  /* color: #4e0eff; */
  max-width: 70%;
  min-width: 50px;
  width: fit-content;
  border: #d9d9d9 solid 1px;
  box-shadow: 1px 1px 10px #ffffff, inset 1px 1px 3px #d9d9d9;
  word-wrap: break-word;
  padding: 0.71rem;
  border-radius: 0px 10px 10px 10px;
  position: relative;
  white-space: pre-line;
}

.chat-main .self {
  color: #fff;
  border-radius: 10px 0px 10px 10px !important;
  box-shadow: 1px 1px 10px #fff, inset 1px 1px 3px #fff;
  background-color: transparent;
  border: solid 1px #fff;
  align-self: end;
}

.chat-main .bubble .time {
  font-size: 0.5rem;
  position: absolute;
  bottom: 0;
  right: 10px;
}

.chat-main .day {
  margin: 10px 0px;
  width: fit-content;
  display: none;
  text-align: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.chat-main .day.visible {
  display: block;
}

.chat-body .day::before {
  content: "";
  display: inline-block;
  width: 150%;
  height: 1px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  right: 105%;
  transform: translateY(-50%);
}

.chat-body .day::after {
  content: "";
  display: inline-block;
  width: 150%;
  height: 1px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 105%;
  transform: translateY(-50%);
}

/* Profile */
.profile-container {
  margin: auto;
  width: 70vw;
  height: 70vh;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-container .profile-image {
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
}

.profile-container .profile-image img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.profile-container p {
  font-size: 1.4rem;
  font-weight: bolder;
  text-overflow: bre;
  word-wrap: break-word;
}

.profile-container label {
  color: #ffffff87;
  font-size: 0.8rem;
  font-weight: 500;
}

.profile-container .info {
  margin: 1rem;
  width: 70%;
  position: relative;
}

/* .profile-container .edit-icon {
  position: absolute;
  font-size: 1.5rem;
  transform: translateY(-50%);
  bottom: 0;
  right: 0;
  cursor: pointer;
}

.profile-image .edit-icon {
  bottom: 20%;
  right: 20%;
  color: #4e0eff;
  background-color: #fff;
} */

.profile-container .update-pswd {
  margin-top: 30px;
  width: 300px;
  height: 50px;
  outline: none;
  background-color: transparent;
  border-radius: 10px;
  border: 2px #fff solid;
  color: #fff;
  font-size: 1.2rem;
  transition: 0.5s;
}

.update-pswd:hover {
  background-color: #4e0eff;
  cursor: pointer;
}

.image-uploader {
  width: 80vw;
  height: 80vh;
  position: relative;
  background-color: #997af0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  border-radius: 10px;
  position: absolute;
  top: 150%;
  left: 50%;
  z-index: 30;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
}

.image-uploader.active {
  top: 50%;
}

.image-uploader .close {
  display: block;
  color: #000;
  font-size: 2rem;
  position: absolute;
  top: 0;
  right: 5px;
  cursor: pointer;
}

.flie-image {
  max-width: 400px;
  width: fit-content;
  height: 40px;
  /* position: absolute; */
  z-index: 30;
  top: 10px;
  left: 10px;
}

.flie-image input {
  font-size: 1rem;
  cursor: pointer;
  top: 0;
  left: 0;
}

input::file-selector-button {
  color: #47a7d7;
  background-color: #38314d64;
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 10px;
}

.cropping-section {
  width: 90%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.image-selected {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.preview-image {
  color: #fff;
  letter-spacing: 5px;
}

.preview-image img {
  height: 300px;
  width: auto;
  border-radius: 50%;
}

.image-uploader button {
  padding: 0.5rem;
  background-color: #38314d64;
  outline: none;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
}

/* User-profile */
.user-profile {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #131324;
  color: #d9d9d9;
  gap: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 25;
}

.user-profile .profile-pic {
  height: 150px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  position: relative;
}

.user-profile .profile-pic img {
  height: 100%;
  border-radius: 50%;
}

.user-profile label,
.user-profile p {
  width: 40%;
  color: #d9d9d9;
  font-size: 1rem;
  text-align: left;
}

.user-profile p {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
  position: relative;
}

.user-profile .close-btn {
  font-size: 2rem;
  width: 30px;
  height: 30px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  right: 10px;
  transform: rotate(45deg);
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
}

.edit-icon {
  background-color: #4e0eff;

  /* height: 30px; */
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

.profile-pic .edit-icon {
  font-size: 2rem;
  right: 10px;
  cursor: pointer;
}

.edit-input {
  font-size: 1.2rem;
  font-weight: bold;
  color: #d9d9d9;
  background-color: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid #d9d9d9;
  position: relative;
}

.edit-update-btn {
  padding: 0px 10px;
  margin-left: 10px;
  background-color: transparent;
  border: 1px solid #d9d9d9;
  color: #d9d9d9;
  font-size: 1.2rem;
  border-radius: 5px;
  transition: 0.5s;
}

.edit-update-btn:hover {
  color: #131324;
  background-color: #d9d9d9;
  border: 1px solid #131324;
}

.edit-update-btn + span {
  cursor: pointer;
  margin-left: 10px;
}

/* DP Viewer */
.dp-container {
  width: 100%;
  height: 100%;
  position: absolute;
  transition: 1s;
  top: 0;
}

.dp-viewer {
  width: 60%;
  height: fit-content;
  background-color: #070731;
  border: 1px solid #d9d9d9;
  /* border-radius: 5px; */
  overflow: hidden;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
}

.dp-viewer .name {
  color: #131324;
  /* position: absolute; */
  padding: 0.5rem;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #d9d9d9;
}

.dp-viewer img {
  padding: 5px 5px 0px;
  border-radius: 50%;
  width: 100%;
  aspect-ratio: 1;
}

@media screen and (max-width: 900px) and (min-width: 700px) {
  .chat-header h3 {
    font-size: 1rem;
  }

  .contact {
    font-size: 0.8rem;
  }
  .contact .contact-name {
    width: 60% !important;
  }
  .user-profile label,
  .user-profile p {
    width: 60%;
  }
}

@media screen and (max-width: 700px) {
  .chat-container {
    width: 100vw;
    height: 100vh;
  }
  .chat-container .chat-left-container {
    width: 100% !important;
  }

  .chat-right-container {
    width: 100vw !important;
    border-left: none !important;
    background-color: #131324;
  }
  .add-chat-container {
    width: 100% !important;
  }
  .chat-container .chat-body .chat-body-header .chat-close-icon {
    display: block;
    cursor: pointer;
  }
  .chat-right-container.closed {
    display: none !important;
  }

  .contact .contact-name {
    width: 85%;
  }

  .profile-container {
    width: 90vw;
    height: 90vh;
  }

  .profile-container label {
    font-size: 0.7rem;
  }
  .profile-container p {
    width: 100%;
    font-size: 0.9rem;
  }

  .user-profile label,
  .user-profile p {
    width: 80%;
  }

  .dp-viewer {
    width: 40% !important ;
  }
}
